@import "config";

//set up container
// .container {
//     max-width: $website-width;
//     padding: 0 1.5rem;
//     margin: auto;
//     overflow: hidden;
//   }

  .dark-overlay{
    position: absolute;
      top:0;
      left:0;
      background: rgba(0,0,0,0.7);
      width:100%;
      height: 100%;
  }


//margin and padding
.m {
    // margin top
    &t {
      &-10p {
        margin-top: 10px !important;
      }
      &-1 {
        margin-top: 1rem !important;
      }
      &-2 {
        margin-top: 2rem !important;
      }
      &-3 {
        margin-top: 3rem !important;
      }
      &-7{
          margin-top: 5.5rem !important;
      }
    }
  
    // margin bottom
    &b {
      &-0{
        margin-bottom:0 !important;
      }
      &-1 {
        margin-bottom: 1rem !important;
      }
      &-2 {
        margin-bottom: 2rem !important;
      }
      &-3 {
        margin-bottom: 3rem !important;
      }
    }
  
    // Margin top and bottom
    &y {
      &-1 {
        margin: 1rem 0 !important;
      }
      &-2 {
        margin: 2rem 0 !important;
      }
      &-3 {
        margin: 3rem 0 !important;
      }
      &-4 {
        margin: 4rem 0 !important;
      }
    }
  }
  

  .p {
    // padding all around
      &-1 {
        padding: 1rem !important;
      }
    
      &-2 {
        padding: 2rem !important;
      }
      &-3 {
        padding: 3rem !important;
      }
    
      // padding top and bottom
      &y{
        &-1 {
            padding: 1rem 0rem !important;
          }
          &-2 {
            padding: 2rem 0rem !important;
          }
          &-3 {
            padding: 3rem 0rem !important;
          }
      }
    
    }


.bottom-line{
    height: 2px;
    width: 3rem;
    background: $main-color;
    display: block;
    margin: 0 auto 1rem auto;
}

.bottom-line-long{
    height: 2px;
    width: 100%;
    background: $main-color;
    display: block;
    margin: 0 auto 1rem auto;
}


.card{
    padding:1rem;
    border-radius:10px;
    background:#fff;
}

//button formatting
.btn {
    border: none;
    display: inline-block;
}

.btn-app{
    background-color: $main-color;
    border-radius:5px;
    padding:10px 8px;
    color:#fff;
    // width: 50%;
    text-align: center;
}

.btn-app:hover{
    background-color: #4392b5;
    cursor: pointer;
}

.btn-disabled {
  background-color:gray;
  outline: none !important;
  border:none;

}

.btn-disabled:hover{
  color:#fff;
  background-color:gray;
  outline: none !important;
  border:none;

  cursor: default !important;

}

.btn-disabled:focus{
  outline:none;
}

.btn-uni{
  background-color: $main-color;
  border-radius:5px;
  color:#fff;
  text-align: center;
}

.btn-uni:hover{
  background-color: #4392b5;
  color: #fff !important;;
  // font-weight: bold;
  cursor: pointer;
}
.btn-secondary:hover{
  font-weight: bold !important;
}

.btn-askmedication{
  background-color: $urgent-color;
  border-radius:5px;
  padding:10px 8px;
  color:#fff;
  // width: 50%;
  text-align: center;
}

.btn-askmedication:hover{
  background-color: #ac4242;
  cursor: pointer;
}

.important-info-link{
    color:$main-color !important;
    margin: 10px 5px;
    font-style: italic;
}

.button-link {
  background-color: transparent !important;
  font-size: 12px;
  border: none;
  cursor: pointer;
}

.button-link:hover {
  text-decoration: underline;
  
}
.button-link:focus{
  outline:none;  
}

//text formatting

.font-it{
    font-style: italic;
}

.title{
    line-height: 1.6;
}

.keywords{
    color: $main-color;
}
.keywords-urgent{
    color:$urgent-color
}
.important-text{
    color:$policy-color;
    font-weight: bold;
}
.lead{
    font-style: italic;
    margin-bottom: 3rem;
}
.center{
    text-align: center;
}

 // set font size
 .f{
     &-1{
        font-size: 1rem !important;
     }
     &-2{
        font-size: 2rem !important;
     }
     &-3{
        font-size: 3rem !important;
     }

}

.list{
    padding:10px;
}

// form formatting
.form-group{
    margin-bottom: 2rem;
    // padding:0 1rem;
    display: block;
}

// file url fomatting

.file-url{
    text-decoration: underline;
    font-style: italic;
    color:$main-color;
}

// current page 
.current {
  // background-color: #42b3e5;
  // color: #fff; 
  // color:$main-color;
  // font-weight: 700 !important;
  // border-bottom: 2px solid $main-color ;
//   width: 100%;
//         left: 0;
//         right:auto;
}

.current::after{
    width: 100% !important;
    left: 0;
    right:auto;
}


.askmedication-url{
  text-decoration: underline;
  font-style: italic;
  color:$urgent-color
}
.display-none{display: none !important;}
.display-block{display: block !important;}

.filled-button {
  font-size: 1rem;
  color: white;
  background-color: #006edc;
  border: 0;
  border-radius: 4px;
  padding-top: 10px;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 10px;

  min-width: 160px;
  /* height:52px; */
}
.filled-button:hover {
  box-shadow: 0 0 4px 0 #4BA0BA;
}
.filled-button:active {
  box-shadow: none;
  background-color: rgba(12,175,223,0.75);
}

.filled-button.disabled {
  /* display: none; */
  background-color: #cccccc;
  pointer-events: none;
  border: none;
}
.filled-button.disabled:hover {
  cursor: pointer;
  box-shadow:none;
}

.filled-button-font{
  font-size: 20px;
}

button.undefined.filled-button.pull-right{
  margin-bottom:30px;
}

.empty-button {
  background-color: rgba(255,255,255,0.1);
  border: 2px solid;
  border-radius: 4px;
  padding:10px 20px;
  min-width: 160px;
  /* height:52px; */
}
.empty-button:hover {
  box-shadow: 0 0 4px 0;
}
.empty-button:active {
  box-shadow: none;
  background-color: rgba(255,255,255,0.5);
}

.empty-button-font {
  font-size: 20px;
}

.pull-right{
  float:right;
}

.alert-box {
  position: fixed;
  z-index: 2000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}

.alert-box-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;

  animation-name: alert-fade;
  animation-duration: 0.4s;
  color:$main-color;

  .close:hover{
    cursor:pointer
  }
  
}

.alert-box-text {
  font-size: x-large;
}

.alert-self-danger {
  padding:.3rem .75rem;
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  border-radius: .25rem;
}


.main-color {
  color: $main-color;
}

/* Spinning Control */
#SpinningControl {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -44px;
  margin-top: -44px;
  z-index: 1;
  width: 88px;
  height: 88px;

  border: 12px solid #0cafdf;
  border-radius: 50%;
  border-top: 12px solid rgba(0,0,0,0);

  animation: spinnerAnimated 1.5s linear infinite;
}
@keyframes spinnerAnimated {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.input-one-fourth-width{
  width: 23.85% !important;
  margin: 2px!important;
}

.pull-left{
  float: left;
}
.phone-label {
  padding: 15px 5px;
}

.color-red {
  color: #f44336;
}

.overlay-background {
  position: fixed;
  z-index: 1001;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.5);
}

// .input-full-width {
//   width: 96%;
//   margin: 2px !important;
// }

.bg-black{
  background-color:black;
  width:100%;
  height: 100px;
  border: 1px solid red;
}


/* Spinning Control */
// try out react-spinners

#spinning-control {
  position: fixed;
  top: 0px;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: black;
  opacity: 0.7;
  z-index: 10000;
  .text {
    position:absolute;
    top:58%;
    color: $main-color;
    // color: black;

    font-size: 18px;
    // padding-left: 20px
}

}

.input-error {
  border-color: red !important;
}
.under-line{
  text-decoration: underline;
}

.font-1{
  font-size: 1rem;
}

.bold{
  font-weight: bold;
}

.centered{
  text-align: center;
}
.full-width {
  width: 100%;
}
.display-block{display:block;}