// @import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");
@use 'sass:math';
@import url("https://fonts.googleapis.com/css?family=Open+Sans&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Fredoka&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import './variable.scss';


@import './_config.scss';
@import './_utilities.scss';
@import './navBarLogout.scss';
@import '../../MedicalInfo/edit-medical-info-page.scss';
@import '../../MedicalInfo/medicalInfo.scss';
@import '../../MedicalInfo/other-allergy.scss';
@import '../../MedicalInfo/other-surgery.scss';
@import '../../MedicalInfo/icd-select.scss';


// General Styles reset
* {
  margin: 0;
  padding: 0;
}
*,
*::before,
*::after {
  box-sizing: none; // 1
}


body {
  font-family: "Roboto", sans-serif;
  background-color: $light-color;
}

a {
  text-decoration: none;
  color: $dark-color;
}

ul {
  list-style: none;
}

h1{
  font-size:2em;
}
h2{
  font-size:1.5em;
}
h3{
  font-size:1.3em;
}
h4{
  font-size:1em;
}
h5{
  font-size:0.8em;
}
h6{
  font-size:0.7em;
}

.inactive {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  -webkit-user-drag: none;
  user-drag: none;
}

// nav bar
#main-nav {
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  padding: 1rem 2rem;

  align-items: center;
  border-bottom: 1px solid #f0f0f0;
  
  margin: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background:$light-color;
  width: 100%;

  .nav-bars-icon{
    margin: 0 0.5rem;
    display:none;
    .close-icon{
      display: none;
    }
  }
  .logo {
    color: $dark-color;
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
  }

  .logo:hover {
    a {
      text-decoration: none;
      color: $main-color;
    }
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;

    .nav-bar-links {
      display: flex;
      align-items: center;
    }

    li {
      margin: 0.6rem 1rem;
      text-align: center;

      a {
        position: relative;
      }

      a:hover {
        text-decoration: none;
        color: #333;
      }

      a::after{
        content: "";
        position: absolute;
        z-index: -1;
        right: 0;
        width: 0;
        bottom: -5px;
        background: $main-color;
        height: 3px;
        transition-property: width;
        transition-duration: 0.4s;
        transition-timing-function: ease-out;
      }
      a:hover::after,
      a:focus:after,
      a:active:after
      {
        width: 100%;
        left: 0;
        right:auto;
      }
    }

  }
  .user-name{
    margin-left:20px;
    font-size: 16px;
  }
  // @media only screen and (max-width:1280px) {
  //   justify-content: space-evenly;
  //   .logo{
  //       display: none;
  //   }
  // }

}

.alert-fixed {
  position: fixed;
  top:100px;
  width: 100%;
  padding: 1.25rem 1.25rem;
  z-index: 1000;
}

//showcase
#showcase {
  background: $bg-image no-repeat center right / cover;
  height: 80vh;

  position: relative;
  // top: 1.5rem;
  .showcase-content {
    position: absolute;
    color: #fff;
    text-align: center;
    // padding: 35% 0;
    top:45%;
    left: 0;
    right: 0;

    h1 {
      line-height: 1.2;
      font-family: "Open Sans", sans-serif;
      margin-bottom: 20px;
    }
  }
}

#twitter-container{
  // background: #243447;
  background: black;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  
}

#anouncement{

  padding:40px 0;
  background-color: #151414;
  color: white;

  .separator{
    border-bottom: 2px solid #eae7ff;
  }
}



//   services
#services {
  h2 {
    text-align: center;
    padding: 2rem;
  }
  // span{display: block !important;}
  .lead {
    text-align: center;
  }
  .learn-more{
    color:$urgent-color;
    display: none;
    background: transparent;
    font-style: italic;
    border:none;
    text-decoration: underline;
  }
  .show{
    height: 13rem !important;
  }
  .show-text{
    display: inline-block !important;
  }
  .learn-more:hover{
    text-decoration: underline;
  }
  .learn-more:focus{
    outline:none;
  }
  .patients-cat-1 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    div {
      padding: 3rem 2rem;
      color: #fff;
      .title{
        color:$urgent-color;
        text-align: left;
        margin-top: 35px;
      }

      p {
        text-align: left;
        height:15rem;
        line-height: 1.5;
      }

      &:nth-child(even) {
        background: $ligmid-color;
      }

      &:nth-child(odd) {
        background: $dark-color;
      }
      a{
        width: 100%;
      }
    }

  }

  .patients-cat-3 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    div {
      padding: 3rem 2rem;
      color: #fff;
      .title{
        color:$urgent-color;
        text-align: left;
        margin-top: 35px;
      }

      p {
        text-align: left;
        height:15rem;
        line-height: 1.5;
      }

      &:nth-child(even) {
        background: $dark-color;
      }

      &:nth-child(odd) {
        background: $ligmid-color;
      }
      a{
        width: 100%;
      }
    }

  }

  .patients-cat-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    div {
      padding: 3rem 2rem;
      color: #fff;
      .title{
        color:$urgent-color;
        text-align: left;
        margin-top: 35px;
      }

      p {
        text-align: left;
        height: 15rem;
        line-height: 1.5;
      }

      &:nth-child(odd) {
        background: $ligmid-color;
      }

      &:nth-child(even) {
        background: $dark-color;
      }
      a{
        width: 100%;
      }
    }

  }
  .patients-cat-4 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    div {
      padding: 3rem 2rem;
      color: #fff;
      .title{
        color:$urgent-color;
        text-align: left;
        margin-top: 35px;
      }

      p {
        text-align: left;
        height: 15rem;
        line-height: 1.5;
      }

      &:nth-child(odd) {
        background: $ligmid-color;
      }

      &:nth-child(even) {
        background: $dark-color;
      }
      a{
        width: 100%;
      }
    }

  }
}

// how to get here
#nav-here {
  .nav-here-steps {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    color: $dark-color;
    text-align: center;
    img {
      width: 90%;
      height: 300px;
      border-radius: 5px;
    }
    h1 {
      margin-top: 15px;
      line-height: 1.4;
    }
  }
}

// map
#map-container {
  text-align: center;

  #map {
    width: 100%;
    height: 400px;
  }
}

// policies
#policies {
  margin-top:79px;

  .policy-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3rem;

    article {
      h3 {
        text-align: center;
      }
      div:first-child {
        // background-color: #fff;
        padding: 1rem;
      }
    }

    article:nth-child(3) {
      grid-column: 1 / span 2;
    }
  }
}

//faqs

#faq {

  padding: 3rem 0 !important;

  .mbr-iconfont {
    font-family: "Moririse2" !important;
    display: inline-flex;
    // -webkit-transform-origin: ;
    // transform-origin: ;
    // -webkit-transform: rotate(45deg) !important;
    // transform: rotate(45deg) !important;
    -webkit-transition: all 0.3s !important;
    transition: all 0.3s !important;
    padding: 0 !important;
    font-size: 1.4rem !important;
    color: $main-color;
  }

  .mbr-iconfont:before {
    -webkit-transform: scaleY(0) !important;
    transform: scaleY(0) !important;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
  }
  .collapsed {
    .mbr-iconfont {
      -webkit-transform: rotate(0deg)!important;
      transform: rotate(0deg) !important;
      // color:white !important;
    }
    .mbr-iconfont:before {
      -webkit-transform: scaleY(1) !important;
      transform: scaleY(1) !important;
      -webkit-transition: all 0.3s !important;
      transition: all 0.3s !important;
    }
  }

  .row{
    padding:1rem !important;
  }
  h4:hover{
    color:$main-color !important;
    .sign {
      padding-right: 1rem;
      line-height: 1.6 !important;
    }
  }

  .card{
    padding:5px 0;
    background:transparent;
    border:none;
    display: -webkit-flex;
    border-radius: 0px;
    border-bottom: 2px solid $light-medium-color !important;

    
    .card-header {
      border-radius: 0px;
      border: 0px;
      background-color: transparent;
      padding: 0;
      
      a{
        .panel-title{
          .toggle-icon {
            right: 7px;
            position: absolute;
            top: 36px;
            -webkit-transform: none;
            transform: none;
            display: inline-block;
            height: 2px;
            margin-left: 0;
            width: 12px;
          }

          .toggle-icon:before,
          .toggle-icon:after {
            background: currentColor;
            border: 0;
            box-sizing: border-box;
            content: "";
            display: block;
            left: 50%;
            position: absolute;
            top: 50%;
          }

          .toggle-icon:before {
            height: 12px;
            margin-left: -1px !important;
            width: 2px;
            margin-top: -6px;
            background-color: currentColor;
            border: 0;
            -webkit-transform: none;
            transform: none;
          }
          .toggle-icon:after {
            height: 2px;
            width: 12px;
            margin-left: -6px;
            margin-top: -1px;
          }


        }
      }

      .panel-title {
        margin-bottom: 0;
        display: flex;
        width: 100%;
        justify-content: space-between;

        // .mbr-iconfont {
        //   font-family: "Moririse2" !important;
        //   display: inline-flex;
        //   transform: rotate(45deg);
        //   transition: all 0.3s;
        //   padding: 0 !important;
        //   font-size: 1.4rem !important;
        //   color: $main-color;
        // }

        .toggle-icon {
          right: 7px;
          position: absolute;
          top: 36px;
          -webkit-transform: none;
          transform: none;
          display: inline-block;
          height: 2px;
          margin-left: 0;
          width: 12px;
        }
        .toggle-icon:before{
          height: 12px;
          margin-left: -1px !important;
          width: 2px;
          margin-top: -6px;
          background-color: currentColor;
          border: 0;
          -webkit-transform: none;
          transform: none;
        }
        .toggle-icon:after{
          height: 2px;
          width: 12px;
          margin-left: -6px;
          margin-top: -1px;
        }

        .toggle-icon:before,
        .toggle-icon:after {
          background: currentColor;
          border: 0;
          box-sizing: border-box;
          content: "";
          display: block;
          left: 50%;
          position: absolute;
          top: 50%;
        }

        h4{
          padding: 1.2rem 0rem;
          margin-bottom: 0;
          font-weight: 400;
          line-height: 1.5;
          letter-spacing: -0.5px;
          justify-content: flex-start;
          width: 100%;
          color: #28303f;
          .sign {
            padding-right: 1rem;
            line-height: 1.6 !important;
          }

        }
      }
      .panel-title:hover{
        text-decoration:none;
        // color:#000;
      }
      .text-black{
        color:#000;
      }
      .display-7 {
        font-family: 'Muli', sans-serif;
        font-size: 1.125rem;
      }

    }

    .panel-body{
      padding:14px 20px;
      p {
        color:#6d7a8c;
        line-height: 1.5;
      }
      .display-4 {
        font-size: 16px;
        font-family: 'Muli', sans-serif;
      }
      .mbr-fonts-style{

      }
    }
  }
  
}
#immi {
  // margin-top:79px;

  padding: 3rem 0 !important;
  width:90%;
  margin: 79px auto 4rem auto;

  .floating-btn-container{
   
    position:fixed;
    bottom: 10px;
    right: 100px;
    z-index: 1000!important;
  }
  .btn-float{
    background-color: $main-color;
  }


  .note{
    font-style: italic;
  }

  .fee-item{
    margin-top:2rem;
    // background-color: #f9f9f9;
    border:1px solid #ccc;
    padding:0.5rem 1rem;
    border-radius: 3px;
  }

  .imm-faq{
    margin-top:2rem;
    // background-color: #f9f9f9;
    border:1px solid #ccc;
    padding:0.5rem 1rem;
    border-radius: 3px;
  }


  .mbr-iconfont {
    font-family: "Moririse2" !important;
    display: inline-flex;
    // -webkit-transform-origin: ;
    // transform-origin: ;
    // -webkit-transform: rotate(45deg) !important;
    // transform: rotate(45deg) !important;
    -webkit-transition: all 0.3s !important;
    transition: all 0.3s !important;
    padding: 0 !important;
    font-size: 1.4rem !important;
    color: $main-color;
  }

  .mbr-iconfont:before {
    -webkit-transform: scaleY(0) !important;
    transform: scaleY(0) !important;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
  }
  .collapsed {
    .mbr-iconfont {
      -webkit-transform: rotate(0deg)!important;
      transform: rotate(0deg) !important;
      // color:white !important;
    }
    .mbr-iconfont:before {
      -webkit-transform: scaleY(1) !important;
      transform: scaleY(1) !important;
      -webkit-transition: all 0.3s !important;
      transition: all 0.3s !important;
    }
  }

  .row{
    padding:1rem !important;
  }
  h4:hover{
    color:$main-color !important;
    .sign {
      padding-right: 1rem;
      line-height: 1.6 !important;
    }
  }

  .card{
    padding:5px 0;
    background:transparent;
    border:none;
    display: -webkit-flex;
    border-radius: 0px;
    border-bottom: 2px solid $light-medium-color !important;

    
    .card-header {
      border-radius: 0px;
      border: 0px;
      background-color: transparent;
      padding: 0;
      
      a{
        .panel-title{
          .toggle-icon {
            right: 7px;
            position: absolute;
            top: 36px;
            -webkit-transform: none;
            transform: none;
            display: inline-block;
            height: 2px;
            margin-left: 0;
            width: 12px;
          }

          .toggle-icon:before,
          .toggle-icon:after {
            background: currentColor;
            border: 0;
            box-sizing: border-box;
            content: "";
            display: block;
            left: 50%;
            position: absolute;
            top: 50%;
          }

          .toggle-icon:before {
            height: 12px;
            margin-left: -1px !important;
            width: 2px;
            margin-top: -6px;
            background-color: currentColor;
            border: 0;
            -webkit-transform: none;
            transform: none;
          }
          .toggle-icon:after {
            height: 2px;
            width: 12px;
            margin-left: -6px;
            margin-top: -1px;
          }


        }
      }

      .panel-title {
        margin-bottom: 0;
        display: flex;
        width: 100%;
        justify-content: space-between;

        // .mbr-iconfont {
        //   font-family: "Moririse2" !important;
        //   display: inline-flex;
        //   transform: rotate(45deg);
        //   transition: all 0.3s;
        //   padding: 0 !important;
        //   font-size: 1.4rem !important;
        //   color: $main-color;
        // }

        .toggle-icon {
          right: 7px;
          position: absolute;
          top: 36px;
          -webkit-transform: none;
          transform: none;
          display: inline-block;
          height: 2px;
          margin-left: 0;
          width: 12px;
        }
        .toggle-icon:before{
          height: 12px;
          margin-left: -1px !important;
          width: 2px;
          margin-top: -6px;
          background-color: currentColor;
          border: 0;
          -webkit-transform: none;
          transform: none;
        }
        .toggle-icon:after{
          height: 2px;
          width: 12px;
          margin-left: -6px;
          margin-top: -1px;
        }

        .toggle-icon:before,
        .toggle-icon:after {
          background: currentColor;
          border: 0;
          box-sizing: border-box;
          content: "";
          display: block;
          left: 50%;
          position: absolute;
          top: 50%;
        }

        h4{
          padding: 1.2rem 0rem;
          margin-bottom: 0;
          font-weight: 400;
          line-height: 1.5;
          letter-spacing: -0.5px;
          justify-content: flex-start;
          width: 100%;
          color: #28303f;
          .sign {
            padding-right: 1rem;
            line-height: 1.6 !important;
          }

        }
      }
      .panel-title:hover{
        text-decoration:none;
        // color:#000;
      }
      .text-black{
        color:#000;
      }
      .display-7 {
        font-family: 'Muli', sans-serif;
        font-size: 1.125rem;
      }

    }

    .panel-body{
      padding:14px 20px;
      p {
        color:#6d7a8c;
        line-height: 1.5;
      }
      .display-4 {
        font-size: 16px;
        font-family: 'Muli', sans-serif;
      }

    }
  }
  
}

// links
#links {
  display: none;
  img {
    width: 50%;
  }
  .links-container-top,
  .links-container-bottom {
    display: grid;
    grid-gap: 3rem;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-items: center;

    div:first-child {
      grid-row: 1 / span 3;
    }
  }
}

// appiontment
#appiontment {
  margin-top:79px;

  .appiontment-container {
    padding: 3rem 1rem;

    .book-btn {
      text-align: center;
    }
  }
}

// contact page

#contact {
  .contact-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    .contact-form {
      padding: 2rem 3rem;
      label {
        font-weight: bold;
        margin-bottom: 10px;
        display: block;
      }
      input {
        width: 100%;
        border-radius: 5px;
        padding: 8px 3px;
        font-size: 17px;
      }
      input:focus {
        outline: none;
        border-color: $main-color;
      }
      textarea {
        width: 100%;
        height: 10rem;
        font-size: 17px;
        border-radius: 5px;
        padding: 0 5px;
      }

      textarea:focus {
        outline: none;
        border-color: $main-color;
      }
    }
  }
}

#main-footer {
  background: $dark-color;
  color: $light-color;

  padding: 2rem 0;

  .footer-container {
    display: grid;
    grid-template-rows: 3fr, 1fr;
    align-items: center;
    // grid-gap: 2rem;

    .footer-container-bottom {
      text-align: center;
      padding: 0.75rem 0;
      background: $ligmid-color;
      border-radius: 5px;
    }
    .footer-container-top {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 2rem;
      p {
        padding: 0.4rem 1.2rem;
        margin-bottom:1rem;
      }
    }
  }
}

// loading page
#page {
  display: none;
}
#loading {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  height: 100vh;
  background-color: $light-color;
  background-image: $loader-image;
  background-repeat: no-repeat;
  
  background-position: center;
}



#login-container {

  // background-color: #eaeaea;
  
  // font-family:"Helvetica","Arial",sans-serif; //Fredoka
  //font-family:"Fredoka","Arial",sans-serif; //Fredoka
  font-family: 'Poppins', sans-serif;



  display: grid;
  // grid-template-columns: repeat(2, 1fr);
  grid-template-columns: 4fr 3fr;


  // margin-top:85px;
  // padding-top: 100px;
  // padding-bottom: 240px;

  // display: flex;
  // justify-content: center;
  align-items: center;
  // margin: auto;
  // background-color: #555;
  text-align: center;
  // height:100vh;
  // min-height: 896px;

  .title-section{
    // margin-bottom: 40px;
    margin-top:30px;
    .welcome-title{
      font-size:1.8rem;
    }
    .content-title{
      font-size: 1.5rem;
    }
    
  }
  .login-icon{
    text-align: center;
    color:$main-color;
    font-size: 50px;
    margin-bottom:20px;
  }
  .g-recaptcha {
    transform:scale(1);
    transform-origin:0 0;
  }

  .login-static-img{
    box-sizing: border-box; 
    box-shadow:0 3px 10px rgb(0 ,0 ,math.div(0, 0.2));
    
    background: $login-image no-repeat center right / cover;
    width:100%;
    // transform:scale(0.8);
    // transform-origin:0 0;
    height: 100vh;
    .helper{
      display: inline-block;
      height: 100%;
      vertical-align: middle;
    }
    img{
      vertical-align: middle;
      max-height: 560px;
      max-width: 640px;
    }
  }

  .floating-btn-container{
   
    position:fixed;
    bottom: 30px;
    right: 20px;
    z-index: 1000!important;
  }
  .btn-login-page{
    background-color: $main-color;
    color:#fff;
  }
  .btn-login-page:hover{
    background-color:#5aa0bf;
  }

  .login-section{
    //  box-shadow: 0px 3px 5px -1px rgb(0, 0, 0 / 0.2), 0px 6px 10px 0px rgb(0, 0, 0 / 0.14), 0px 1px 18px 0px rgb(0, 0, 0 / 0.12);
    // transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  
  }
  .login-panel {
    // width:20%;

    background-color: white;
    border: 1px solid rgb(177, 175, 175);

    border-radius:  5px;
    padding:30px;

    max-width: 430px;
    margin:auto;
    input {
      width: 100%;
      // margin-top:1rem;
      border: 1px solid rgb(177, 175, 175);
      border-radius: 2px;
      height: 55px;
      padding:0 1rem;
      display:block;
    }

    input:hover{
      border: 1px solid black !important;
    }

    button{
      width:100%;
      background-color: $main-color;
    }
    .text-link{
      color:$main-color;
      font-style: italic;
    }
    .text-link:hover{
      text-decoration: none;
    }

    .text-link-red{
      color:red;
      font-style: italic;
    }

    .text-link-red:hover{
      text-decoration:none;
    }

    .minor-text{
      font-size: .8rem;
      color:#555;
    }

    i {
      color:$main-color;
      padding:30px 0;
    }

  }

}

#dashbaord-container{
  
  // min-height: 100vh;
  max-width: 1800px;
  margin-top:100px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  button{
    width:100%;
    // background-color:$main-color;
  }


}

#preferred-pharmacy-container{
  select{
    width:100%;
    height: 55px;
    padding-left: 10px;

  }
  input{
    width:100%;
    height: 55px;
    padding-left: 10px;

  }
  .register-label{
    color:#606060;
    font-weight: bold;
    font-size:15px;
  }
}

.modal-90w{
  width:80vw;
  max-width: none !important;
}

.sick-note-container{
  
  // min-height: 100vh;
  margin-top:100px;
  .react-datepicker-wrapper {
    width: 100%;
  }
  input.form-control, select.form-control, button.form-control {
    height: 55px;
  }
  


}

.payment {
  margin-top: 100px;
  &__fee {
    font-size: 20px;
  }
}

.checkout-form {
  &__card {
    border: 1px solid black;
    max-width: 500px;
    padding: 18px 7px;
    border-radius: 5px;
  }
  &__buttons {
    max-width: 500px;
    &__button {
      width: 100%
    }
  }

}

#register-container {
  // height: 100vh;
  margin-top:100px;

  h1{
    color:$main-color;
  }

  input{
    width:100%;
    border-radius: 2px;
    height: 55px;
    padding:0 1rem;
    border: 1px solid #555;
  }
  select{
    width:100%;
    height: 55px;
    padding-left: 10px;

  }
  .register-label{
    color:#606060;
    font-weight: bold;
    font-size:15px;
  }
  .row{
    margin-bottom:1rem;
  }
  
  
}

#password-reset-container{
  height: 100vh;
  margin-top:100px;
  input{
    width:100%;
    border-radius: 2px;
    height: 55px;
    padding:0 1rem;
    border: 1px solid #555;
  }
  
  select:focus,input:focus{
    outline:none;
    border: 1px solid $medium-color;

  }

  select{
    height:52px;
    width:100%;
    padding-left: 10px;
  }
}

#not-found-container{
  height: 100vh;
  margin-top:100px;
}

#message-reply-page{
  height: 100vh;
  margin-top:140px;
  padding-top: 20px;
}

#account-container{
  
  // min-height: 100vh;
  margin-top:100px;
  color:#353535;

  input {
    width: 100%;
    // margin-top:1rem;
    border: 1px solid #555;
    border-radius: 2px;
    height: 55px;
    padding:0 1rem;
    display:block;
  }
  input:disabled{
    background-color:#E5E5E6 !important;
  }

  select{
    width: 100%;
    height: 55px;
    padding:0 1rem;
    border: 1px solid #555;


  }

  select:disabled{
    background-color:#cacacabd !important; //#cacacabd
    color:rgb(12, 12, 12)
  }

  .row{
    margin-top:10px;
  }

  button{
    width: 100%;
    height:53px;
  }
}

.big-checkbox{
  width: 25px; 
  height: 25px;
  border:2px;
  border-radius:3px;
}
.checkbox-label{
  margin-left:7px;
}


.stack-container{
  position: relative;
  // display:inline-block;
}
.stack-top {
  /* adjust the below to control the shape */
  --d:5px; 
  --g:16px;
  --c:#FF3232;
  /**/

  position: absolute;
  top: 0;
  right: 0;
  transform: translate(29.29%, -100%) rotate(45deg); /* 29.29% = 100%*(1 - cos(45deg)) */
  color: #fff;
  text-align: center;
  width: 200px;
  transform-origin: bottom left;
  padding:5px 0 calc(var(--d) + 5px);
  background:
    linear-gradient(135deg, transparent var(--g), var(--c) calc(var(--g) - 0.3px)) left,
    linear-gradient(-135deg,transparent var(--g), var(--c) calc(var(--g) - 0.3px)) right;
  background-size:51% 100%;
  background-repeat:no-repeat;
  clip-path:polygon(0 0,100% 0,100% 100%, calc(100% - var(--d)) calc(100% - var(--d)), var(--d) calc(100% - var(--d)),0 100%)
}

#pharmacy-select {
  position: relative;

  ul {
      padding: 0px;
      margin: 0px;
      margin-bottom: 50px;
      max-height: 100px;
      width: 100%;
      overflow-y: auto;
      border: 1px solid darkgray;
      border-top: none;
      border-radius: 0px 0px 5px 5px;
      position: absolute;
      z-index: 1;
  
      li:hover {
          background: $highlight-gray;
      }
  
      li {
          width: 100%;
          list-style-type: none;
          padding: 5px 10px;
          border: 0.001em solid #c0c0c0;
          background: $smoke-white;
          cursor: pointer; 
      }
  }
  
  .item {
      .badge {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 16px;
          white-space: normal;
          font-weight: 400;
          text-align: left;
          border-radius: 5px;
          background-color: $badge-gray;
          margin: 2px;
          cursor: pointer;
          .close {
              padding-left: 10px;
              padding-bottom: 5px;
              width: auto;
          }
          
            button {
              width: 145px;
            }
          
      }
  }
}

// terms of use, privacy, and fee container 
.terms-privacy-fee-button-container{
  text-align: center;
  button{
    width:auto !important;
    color:#999da2;
    margin: 10px 5px;
    font-style: italic;
  }
}

#terms-body{
  ol,ul { 
    padding-inline-start: 40px;
  }
  a{
    color:$main-color;
    font-style: italic;
  }

  ul > li {
    margin-bottom: 5px;
  }

  ol {
    counter-reset: list;
    >li{
      margin-bottom: 5px;
      list-style: none;
      &:before{
        content: counter(list, lower-alpha) ") ";
        counter-increment: list;
      }
    }
  }
}

#privacy-policy-body{
  ol,ul { 
    padding-inline-start: 40px;
  }
  ul{
    list-style-type: disc;
  }
  a{
    color:$main-color;
    font-style: italic;
  }
  ul > li {
    margin-bottom: 5px;
  }

  ol {
    counter-reset: list;
    >li{
      margin-bottom: 5px;
      list-style: none;
      &:before{
        content: counter(list, lower-alpha) ") ";
        counter-increment: list;
      }
    }
  }
}



#footer-loggedin{
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 2.5rem;   
  background-color:#eeeef0
}

.file-download-link{
  color:$main-color
}

.file-downloaded-link{
  text-decoration: line-through
}
.file-downloaded-text{
  color:$urgent-color
}
.file-download-text{
  color:$download-available-color
}
@import './media';
