
// variables
$main-color: #42B3E5;
$light-color-prev: #EAF0F1;
$light-color: #FFF;
$light-medium-color: #dddddd;
$medium-color: #0A3D62; 
$ligmid-color: #4C4B4B;
$policy-color:#ff7272;
$dark-color: #333;
$dark-gray: #343a40;
$highlight-gray: $pale-gray;
$light-gray: #f2f2f2;

$pale-gray: #dfdfdf;
$smoke-white: #f5f5f5;
$badge-gray: $light-gray;

$website-width: 1280px;
$bg-image: url('../img/showcase-3-min.webp');
$login-image:url('../img/login/login1-min.webp');

$loader-image: url('../img/loader/loader.gif');
$step-image-1:url('../img/howtogethere/step1.webp');
$step-image-2:url('../img/howtogethere/step2.webp');
$step-image-3:url('../img/howtogethere/step3.webp');
$step-image-4:url('../img/howtogethere/step4.webp');

$download-available-color:#49be25;
// urgent message
$urgent-color:#FF3232;
// Set Text Color
@function set-text-color($color) {
    @if (lightness($color) > 50) {
      @return #000;
    } @else {
      @return #fff;
    }
  }
  