@import './_config.scss';

@media(max-width:1200px){
    #twitter-container{
        display:block !important;
    }
}

    
@media(max-width:1160px){
    #login-container{
        // display: ;
        grid-template-columns: 0fr 1fr;
        padding-top: 50px;
    }
    .login-static-img{
        img{
            width: 0;
            height: 0;
        }
    }
}

// @media(max-width:1000px){
    
//     #services{
//         i{display:none;}
//         .learn-more{
//             display:block !important;
//         }
//         p{
//             height:5rem !important;
//             span{
//                 display: none !important;
//             }
//         }
//         .patients-cat-1{
//             display: block;
//         }
//         .patients-cat-2{
//             display: block;
//             div {
//                 &:nth-child(even) {
//                     background: $ligmid-color;
//                 }
            
//                 &:nth-child(odd) {
//                 background: $dark-color;
//                 }
//             }
//         }
//         .patients-cat-3{
//             display: block;
//         }
//         .patients-cat-4{
//             display: block;
//             div {
//                 &:nth-child(even) {
//                     background: $ligmid-color;
//                 }
            
//                 &:nth-child(odd) {
//                 background: $dark-color;
//                 }
//             }
//         }
//     }

// }

@media (max-width: 992px) {
    #footer-loggedin{
        position: relative;
        margin-top:15px;
    }
}

@media (max-width: 800px) {
    // #header-home{
    //     #main-nav{
            
    //         .logo{
    //             display: none;
    //         }
    //         ul{
    //             display: block;
    //             text-align: center;
    //              width: 100%;
                
    //             li{
    //                 margin: 10px 0;
                    
    //                 a{
    //                     display: block;
    //                     width: 100%;
    //                     padding: 1rem 0;
                       
    //                 }
    //             }
                
    //         }

    //     }
    // }
    #showcase{
        .showcase-content{
            // padding-top: 50%;
            h1{
                // display: none;
                font-size:28px !important;
            }
        }
        .urgent-update-content{
            font-size: 1rem;
        }
    }

    #services{
        i,img{display:none;}
        
        .learn-more{
            display:block !important;
        }
        p{
            height:4.2rem !important;
            margin-top: 0 !important;
            font-size: 13px !important;
            span{
                display: none !important;
            }
        }
        h3{
            font-size: 20px !important;
        }
        .patients-cat-1{
            display: block;
            div{
                padding:10px;
            }
        }
        // .patients-cat-1{
        //     display: block;
        //     div {
        //         padding:10px;

        //         &:nth-child(even) {
        //             background: $ligmid-color;
        //         }
            
        //         &:nth-child(odd) {
        //         background: $dark-color;
        //         }
        //     }
        // }
        // .patients-cat-3{
        //     display: block;
        //     div{
        //         padding:10px;

        //     }
        // }
        .patients-cat-3{
            display: block;
            div {
                padding:10px;

                &:nth-child(even) {
                                background: $ligmid-color;
                            }
                        
                            &:nth-child(odd) {
                            background: $dark-color;
                            }
            }
        }
    }

    #nav-here{
        h1{
            font-size:28px !important;
        }
        .nav-here-steps{
            display: block;
          article{
              margin-top:1rem;
          }
        }
    }

    #main-footer{
       .footer-container{
        
        .footer-container-top{
            display: block;
            div{
                margin-bottom: 2rem;
            }
        }
       } 
    }

    #policies{
        .policy-container{
            display: block;
            article{
                margin-bottom: 2rem;
            }
        }
    }

    #links{
        display: none;
    }

    #appiontment{
        .appiontment-container{
            .book-btn{
                a{
                    span{
                        display: none;
                    }
                }
            }
        }
    }

    #contact{
        .contact-container{
            display: block;
        }
    }
  }
  

  @media (max-width: 420px) {
    #login-container{
        
            .title-section{
            // margin-bottom: 40px;
                .welcome-title{
                font-size:1.2rem;
                }
                .content-title{
                font-size: 1rem;
                margin-top:0 !important;
                }
                
            }
            .floating-btn-container{
                position:relative;
                top:initial;
                bottom:initial;
                left:initial;
                right:initial;
                //   width: 350px;
                    width:100%;
                margin:20px 0 auto;
                //   text-align: center;
            }
            .login-panel{
                width: 350px;
            }
    }
}