.sick-note-medical-info-container{
    margin-top:130px;
}


.medicalinfo{
    

    .medicalinfo-container__past{

        &-text {
            font-size: 15px;
            font-weight: bolder;
            color: #606060;
        }
        ul {
            list-style: none;
            padding-left:0;
            max-height: 200px;
            overflow-y: scroll;
        }
        li {
            margin-bottom:10px;
        }
     }
    .medicalinfo-container {
        width: 100%;
        flex:1;

        ul {
            padding-left: 1.2em;
        }

        .li-medication {
            margin-bottom: 1.5em;
        }

        .info {
            font-size: 0.8em;
            color: $title-gray;
        }

        .row .margin-bottom {
            margin-bottom: 2em;
        }

        hr {
            margin-top: 5px;
            margin-bottom: 15px;
        }

        .medications-container{
            display:flex;
            flex-direction:column;
            
            .medications-heading{
                display:flex;
                align-items: center;
            }
            .medications-title {
                flex:1;
            }
        }
    } 
}


